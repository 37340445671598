<template>
    <section class="w-full">
        <header-register :showButtonView="false">Farmacopeia</header-register>
        <main class="w-full">
            <ManageFarmaCreate/>
        </main>
        </section>
  </template>
  
<script type="module">

import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ManageFarmaCreate from "@/components/groupProducts/farmacopeia/components/create/ManageFarmaCreate.vue";

export default {
    name: "ProvidersEdit",
    components: {
    HeaderRegister,
    ManageFarmaCreate
},
};

</script>